@font-face {
  font-family: 'Varela Round', monospace;
  src: url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
}

body, h1, h2, h3, h4, h5, h6 {
  font-family: "Karma", sans-serif
}
.w3-bar-block .w3-bar-item {
  padding:20px
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
